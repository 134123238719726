import React, { useEffect, useState } from "react";
import "../../InvoiceModal.css";
import { fixedDecimals } from "../../../../helpers/helpers";

const BillingUser = ({ data, type = "Show" }) => {
  const [taxCharges, setTaxCharges] = useState([]);
  const [additionalCharges, setAdditionalCharges] = useState([]);

  const className =
    type == "Show"
      ? "invmodal__shdetails_griditemdisabled"
      : "pinvmodal__shdetails_griditemdisabled";

  const findTaxChargeByNameEn = (nameEn, index) => {
    const taxCharges = data?.shipment_charges[index]?.tax_charges;
    const matchingCharge = taxCharges?.find(
      (charge) => charge?.NameEn === nameEn
    );

    if (matchingCharge) {
      return matchingCharge;
    } else {
      return false;
    }
  };
  const findAdditionalChargeByNameEn = (nameEn, index) => {
    const additionalCharges = data?.shipment_charges[index]?.additional_charges;
    const matchingCharge = additionalCharges?.find(
      (charge) => charge?.NameEn === nameEn
    );

    if (matchingCharge) {
      return matchingCharge;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setTaxCharges(
      data?.shipment_charges
        ?.flatMap((item) => item?.tax_charges)
        ?.filter(
          (charge, index, self) =>
            index === self?.findIndex((c) => c?.NameEn === charge?.NameEn) &&
            charge
        )
    );
    setAdditionalCharges(
      data?.shipment_charges
        ?.flatMap((item) => item?.additional_charges)
        ?.filter(
          (charge, index, self) =>
            index === self?.findIndex((c) => c?.NameEn === charge?.NameEn) &&
            charge
        )
    );
  }, [data]);

  return (
    <>
      <div className="invmodal__shipment_details_wrap">
        <div className="invmodal__shipment_detailshding">
          Billing information
        </div>
        <table className="invmodal__shbilling_grid">
          <thead>
            <th className="invmodal__shdetails_gridhd">#</th>
            <th className="invmodal__shdetails_gridhd">Base</th>
            <th className="invmodal__shdetails_gridhd">Fuel</th>
            <th className="invmodal__shdetails_gridhd">Freight Charge</th>
            <th className="invmodal__shdetails_gridhd">Signature</th>
            <th className="invmodal__shdetails_gridhd">Residential</th>
            <th className="invmodal__shdetails_gridhd">Pickup Charge</th>
            {data?.insurance && (
              <th className="invmodal__shdetails_gridhd">Insurance Charge</th>
            )}
            {data?.shipment_charges &&
              additionalCharges?.map((charge, index) => (
                <th className="invmodal__shdetails_gridhd" key={index}>
                  {charge?.NameEn}
                </th>
              ))}
            <th className="invmodal__shdetails_gridhd">Sub Total</th>
            {data?.shipment_charges &&
              taxCharges?.map((tax, index) => (
                <th className="invmodal__shdetails_gridhd" key={index}>
                  {tax?.NameEn}
                </th>
              ))}
            <th className="invmodal__shdetails_gridhd">Total</th>
          </thead>
          <tbody>
            {data?.shipment_charges?.map((charge, index) => {
              return (
                <tr key={index}>
                  <td className="invmodal__shdetails_griditem">
                    <span>{index + 1}</span>
                  </td>
                  <td className={`invmodal__shdetails_griditem`}>
                    <span>
                      {data?.shipment_charges[index + 1]?.type !== "add" &&
                      index !== data?.shipment_charges.length - 1 &&
                      charge?.base_price ? (
                        <span className={className}></span>
                      ) : (
                        ""
                      )}

                      {charge?.base_price && charge?.base_price !== 0
                        ? charge?.base_price
                        : "-"}
                    </span>
                  </td>
                  <td className="invmodal__shdetails_griditem">
                    <span>
                      {data?.shipment_charges[index + 1]?.type !== "add" &&
                      index !== data?.shipment_charges.length - 1 &&
                      charge?.fuel_charge ? (
                        <span className={className}></span>
                      ) : (
                        ""
                      )}

                      {charge?.fuel_charge && charge?.fuel_charge !== 0
                        ? charge?.fuel_charge
                        : "-"}
                    </span>
                  </td>
                  <td className="invmodal__shdetails_griditem">
                    <span>
                      {data?.shipment_charges[index + 1]?.type !== "add" &&
                      index !== data?.shipment_charges.length - 1 &&
                      charge?.freight_charge ? (
                        <span className={className}></span>
                      ) : (
                        ""
                      )}

                      {charge?.freight_charge && charge?.freight_charge !== 0
                        ? charge?.freight_charge
                        : "-"}
                    </span>
                  </td>
                  <td className="invmodal__shdetails_griditem">
                    <span>
                      {data?.shipment_charges[index + 1]?.type !== "add" &&
                      index !== data?.shipment_charges.length - 1 &&
                      charge?.signature_charge ? (
                        <span className={className}></span>
                      ) : (
                        ""
                      )}

                      {charge?.signature_charge &&
                      charge?.signature_charge !== 0
                        ? charge?.signature_charge
                        : "-"}
                    </span>
                  </td>
                  <td className="invmodal__shdetails_griditem">
                    <span>
                      {data?.shipment_charges[index + 1]?.type !== "add" &&
                      index !== data?.shipment_charges.length - 1 &&
                      charge?.residential_charge ? (
                        <span className={className}></span>
                      ) : (
                        ""
                      )}

                      {charge?.residential_charge &&
                      charge?.residential_charge !== 0
                        ? charge?.residential_charge
                        : "-"}
                    </span>
                  </td>
                  <td className="invmodal__shdetails_griditem">
                    <span>
                      {data?.shipment_charges[index + 1]?.type !== "add" &&
                      index !== data?.shipment_charges.length - 1 &&
                      charge?.PickupCharge ? (
                        <span className={className}></span>
                      ) : (
                        ""
                      )}

                      {charge?.PickupCharge && charge?.PickupCharge !== 0
                        ? charge?.PickupCharge
                        : "-"}
                    </span>
                  </td>
                  {data?.insurance && (
                    <td className="invmodal__shdetails_griditem">
                      <span>
                        {data?.shipment_charges[index + 1]?.type !== "add" &&
                        index !== data?.shipment_charges.length - 1 &&
                        charge?.insurance_charge ? (
                          <span className={className}></span>
                        ) : (
                          ""
                        )}

                        {charge?.insurance_charge &&
                        charge?.insurance_charge !== 0
                          ? charge?.insurance_charge
                          : "-"}
                      </span>
                    </td>
                  )}
                  {data?.shipment_charges &&
                    additionalCharges?.map((charge, idx) => (
                      <td className="invmodal__shdetails_griditem" key={idx}>
                        <span>
                          {data?.shipment_charges[index + 1]?.type !== "add" &&
                          index !== data?.shipment_charges.length - 1 ? (
                            <span className={className}></span>
                          ) : (
                            ""
                          )}

                          {findAdditionalChargeByNameEn(charge?.NameEn, index)
                            ? findAdditionalChargeByNameEn(
                                charge?.NameEn,
                                index
                              )?.Charge
                            : "-"}
                        </span>
                      </td>
                    ))}
                  <td className="invmodal__shdetails_griditem">
                    <span>
                      {data?.shipment_charges[index + 1]?.type !== "add" &&
                      index !== data?.shipment_charges.length - 1 &&
                      charge?.sub_total ? (
                        <span className={className}></span>
                      ) : (
                        ""
                      )}

                      {charge?.sub_total && charge?.sub_total !== 0
                        ? charge?.sub_total
                        : "-"}
                    </span>
                  </td>
                  {data?.shipment_charges &&
                    taxCharges?.map((tax, idx) => (
                      <td className="invmodal__shdetails_griditem" key={idx}>
                        <span>
                          {data?.shipment_charges[index + 1]?.type !== "add" &&
                          index !== data?.shipment_charges.length - 1 ? (
                            <span className={className}></span>
                          ) : (
                            ""
                          )}

                          {findTaxChargeByNameEn(tax?.NameEn, index)
                            ? findTaxChargeByNameEn(tax?.NameEn, index)?.Charge
                            : "-"}
                        </span>
                      </td>
                    ))}
                  <td className="invmodal__shdetails_griditem">
                    {" "}
                    <span>
                      {data?.shipment_charges[index + 1]?.type !== "add" &&
                      index !== data?.shipment_charges.length - 1 &&
                      charge?.total ? (
                        <span className={className}></span>
                      ) : (
                        ""
                      )}
                      {charge?.total && charge?.total !== 0
                        ? fixedDecimals(charge?.total)
                        : "-"}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BillingUser;
