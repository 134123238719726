import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { showSnackBar } from "../../../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";

export default function CompanyDropDown(props) {
  const { List, SetList, handleStepper, Addressbook, setaddressid } = props;
  const dispatch = useDispatch();

  const handleCompanyChange = (event, value, reason) => {
    if (reason === "input") {
      SetList({ ...List, company: value }); // ✅ Keep user input when typing
      handleStepper();
    }
  };

  const handleAddressChange = (event, value) => {
    if (value) {
      SetList({
        ...List,
        address: value?.address ?? "",
        countrycode: value?.countrycode ?? "",
        statecode: value?.statecode ?? "",
        country: value?.country ?? "",
        city: value?.city ?? "",
        state: value?.state ?? "",
        zip: value?.zip ?? "",
        company: value?.company ?? "",
        attention: value?.attention ?? "",
        phone: value?.phone ?? "",
        email: value?.email ?? "",
        notification: value?.notification ?? "",
        otime: value?.otime ?? "",
        ctime: value?.ctime ?? "",
        instruction: value?.instruction ?? "",
        residential: value?.residential ?? "",
      }); // ✅ Update full details with selected company
      const index = Addressbook.findIndex((item) => item.id === value.id);
      setaddressid(index + 1); // ✅ Update address list id
      handleStepper();
      dispatch(
        showSnackBar({
          text: value?.company + " Selected From AddressBook",
          severity: "success",
        })
      );
    }
  };

  return (
    <Autocomplete
      freeSolo
      id="company-address-dropdown"
      sx={{ width: "100%" }}
      options={Addressbook ?? []} // ✅ Full object list
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option?.company || ""
      } // ✅ Handle both string and object
      value={List.company || ""} // ✅ Ensure string value
      onChange={handleAddressChange} // ✅ Handles dropdown selection
      onInputChange={handleCompanyChange} // ✅ Handles manual typing
      renderInput={(params) => (
        <TextField
          {...params}
          label="Company"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Company"
          required
          sx={{ input: { font: "menu" } }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // Disable autocomplete and autofill
          }}
          size="small"
        />
      )}
    />
  );
}
