import { Spinnerbtn } from "./Customloader";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import "./AddressBook.css";
import EditModel from "./EditModel";
import AddressForm from "./AddressForm";
import { setdata } from "../../../../../../helpers/helpers";
import Paginations from "@mui/material/Pagination";
import Confirm from "./Popups/Confirm";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import { showSnackBar } from "../../../../../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";
import {
  createAddress,
  editAddress,
  deleteAddress,
} from "../../../../../../services/ShipperServices/AddressBook/address.services";

export default function AddressBook(props) {
  const {
    AddressBook,
    SetAddressBook,
    StepCheck,
    SetStepCheck,
    List,
    SetList,
    addressid,
    setaddressid,
  } = props;

  const dispatch = useDispatch();

  const [disable, setdisable] = useState();
  const [show, setshow] = useState(false);
  const [showDel, setshowDel] = useState(false);

  const handlemodel = () => {
    setshow(!show);
  };

  const handleaddress = (number) => {
    setaddressid(number);
    if (List["type"] === "Flist") {
      SetStepCheck((prevStepCheck) => ({
        ...prevStepCheck,
        Pickup_Address_bit: 0,
        QuoteCheck: false,
      }));
    } else {
      SetStepCheck((prevStepCheck) => ({
        ...prevStepCheck,
        Delivery_Address_bit: 0,
        QuoteCheck: false,
      }));
    }
    SetList({
      ...List,
      address: setdata(() => AddressBook[number - 1]["address"], false, ""),
      countrycode: setdata(
        () => AddressBook[number - 1]["countrycode"],
        false,
        ""
      ),
      statecode: setdata(() => AddressBook[number - 1]["statecode"], false, ""),
      country: setdata(() => AddressBook[number - 1]["country"], false, ""),
      city: setdata(() => AddressBook[number - 1]["city"], false, ""),
      state: setdata(() => AddressBook[number - 1]["state"], false, ""),
      zip: setdata(() => AddressBook[number - 1]["zip"], false, ""),
      company: setdata(() => AddressBook[number - 1]["company"], false, ""),
      attention: setdata(() => AddressBook[number - 1]["attention"], false, ""),
      phone: setdata(() => AddressBook[number - 1]["phone"], false, ""),
      email: setdata(() => AddressBook[number - 1]["email"], false, ""),
      notification: JSON.parse(AddressBook[number - 1]["notification"]),
      otime: setdata(() => AddressBook[number - 1]["otime"], false, ""),
      ctime: setdata(() => AddressBook[number - 1]["ctime"], false, ""),
      instruction: setdata(
        () => AddressBook[number - 1]["instruction"],
        false,
        ""
      ),
      residential: JSON.parse(AddressBook[number - 1]["residential"]),
    });
  };

  const handleaddressadd = async () => {
    setdisable("add");

    const payload = {
      addressid: addressid,
      phone: List["phone"],
      address: List["address"],
      country: List["country"],
      state: List["state"],
      city: List["city"],
      zip: List["zip"],
      company: List["company"],
      attention: List["attention"],
      email: List["email"],
      notification: List["notification"],
      otime: List["otime"],
      ctime: List["ctime"],
      date: List["date"],
      instruction: List["instruction"],
      countrycode: List["countrycode"],
      statecode: List["statecode"],
      residential: List["residential"],
    };

    const response = await createAddress(payload);

    if (!response.error) {
      SetAddressBook(response?.data?.data);
      setdisable("");
      dispatch(
        showSnackBar({
          text: response?.data?.message,
          severity: "success",
        })
      );
    } else {
      setdisable("");
      dispatch(
        showSnackBar({
          text: "Something went wrong",
          severity: "error",
        })
      );
    }
  };

  const handleaddressdel = async () => {
    setdisable("del");

    const response = await deleteAddress(AddressBook[addressid - 1]["id"]);

    if (!response.error) {
      SetAddressBook(response?.data?.data);
      SetList({
        ...List,
        address: "",
        countrycode: "",
        statecode: "",
        country: "",
        city: "",
        state: "",
        zip: "",
        company: "",
        attention: "",
        phone: "",
        email: "",
        notification: false,
        residential: false,
        otime: "",
        ctime: "",
        instruction: "",
      });
      setaddressid(0);
      setdisable("");
      dispatch(
        showSnackBar({
          text: response?.data?.message,
          severity: "success",
        })
      );
    } else {
      setdisable("");
      dispatch(
        showSnackBar({
          text: "Something went wrong",
          severity: "error",
        })
      );
    }
  };

  const handleaddressedit = async () => {
    setdisable("edit");

    const payload = {
      addressid: addressid,
      phone: List["phone"],
      address: List["address"],
      country: List["country"],
      state: List["state"],
      city: List["city"],
      zip: List["zip"],
      company: List["company"],
      attention: List["attention"],
      email: List["email"],
      notification: List["notification"],
      otime: List["otime"],
      ctime: List["ctime"],
      date: List["date"],
      instruction: List["instruction"],
      countrycode: List["countrycode"],
      statecode: List["statecode"],
      residential: List["residential"],
    };

    const response = await editAddress(
      payload,
      AddressBook[addressid - 1]["id"]
    );

    if (!response.error) {
      SetAddressBook(response?.data?.data);
      setdisable("");
      handlemodel();
      dispatch(
        showSnackBar({
          text: response?.data?.message,
          severity: "success",
        })
      );
    } else {
      setdisable("");
      dispatch(
        showSnackBar({
          text: "Something went wrong",
          severity: "error",
        })
      );
    }
  };

  let items = [];

  for (let number = 1; number <= AddressBook.length; number++) {
    items.push(
      <Pagination.Item
        onClick={() => handleaddress(number)}
        key={number}
        active={number === addressid}
      >
        {number}
      </Pagination.Item>
    );
  }

  const handlePageChange = (event, page) => {
    handleaddress(page);
    setaddressid(page);
  };

  return (
    <>
      <Confirm
        open={showDel}
        setOpen={setshowDel}
        handleConfirm={handleaddressdel}
        text={
          "Do you want to Delete this Address ID : " +
          AddressBook[addressid - 1]?.company +
          " ?"
        }
      />

      <br />

      <div className="formdata__pagination_id_controler">
        <div className="formdata__pagination_controler_id_Num">
          <Tooltip
            title={
              addressid > 0
                ? addressid + "-" + List["company"]
                : "ID Not Selected"
            }
          >
            <span>
              ID: ({" "}
              <span
                className={
                  addressid > 0
                    ? "formdata__pagination_controler_id_Num_span"
                    : "formdata__pagination_controler_id_Num_span tot_zero"
                }
              >
                {" "}
                {addressid > 0
                  ? addressid + "-" + List["company"]
                  : addressid}{" "}
              </span>
              )
            </span>
          </Tooltip>
        </div>
        <div className="formdata__pagination_controler_select_id">
          <small>Select ID</small>
          <Paginations
            count={AddressBook.length}
            page={addressid}
            onChange={handlePageChange}
            shape="rounded"
            color="primary"
            size="small"
            siblingCount={1}
            boundaryCount={0}
            variant="outlined"
            sx={{
              "& .Mui-selected": {
                backgroundColor: "#1976D2 !important",
                color: "#ffffff !important",
              },
              "& .MuiPaginationItem-root": {
                backgroundColor: "#ffffff",
                color: "#A195FD",
              },
            }}
          />
        </div>

        {StepCheck["fullform"] === true && (
          <div className="formdata__pagination_controler_btn">
            <Tooltip title="Add to AddressBook">
              <button
                type="button"
                onClick={() => handleaddressadd()}
                disabled={disable === "add" ? true : false}
                className="formdata__pagination_controler_btn_btnadd"
              >
                {disable === "add" ? (
                  <div className="p-1">
                    <Spinnerbtn
                      style={{
                        zIndex: 1,
                      }}
                    />
                  </div>
                ) : (
                  "Add"
                )}
              </button>
            </Tooltip>
            {AddressBook.length > 0 && addressid > 0 && (
              <>
                <Tooltip title="Edit AddressBook">
                  <a
                    onClick={handlemodel}
                    type="button"
                    className="formdata__pagination_controler_btn_btnedit"
                  >
                    Edit
                  </a>
                </Tooltip>
                <Tooltip title="Delete from AddressBook">
                  <button
                    onClick={() => setshowDel(!show)}
                    type="button"
                    disabled={disable === "del" ? true : false}
                    className="formdata__pagination_controler_btn_btndlt"
                  >
                    {disable === "del" ? (
                      <div className="p-1">
                        <Spinnerbtn
                          style={{
                            zIndex: 1,
                          }}
                        />
                      </div>
                    ) : (
                      "Delete"
                    )}
                  </button>
                </Tooltip>
              </>
            )}
          </div>
        )}
      </div>

      <EditModel
        Show={show}
        Disable={disable}
        HandleClose={handlemodel}
        HandleAddressEdit={handleaddressedit}
        AddressId={addressid}
      >
        <AddressForm
          List={List}
          SetList={SetList}
          StepCheck={StepCheck}
          SetStepCheck={SetStepCheck}
        />
      </EditModel>
    </>
  );
}
