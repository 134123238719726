import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddressForm from "./AddressForm";
import AddressBook from "./AddressBook";
import AddressFormCard from "./AddressFormCard";
import { useEffect, useState } from "react";
import { MdSwapHorizontalCircle } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import { fetchAllAddresses } from "../../../../../../services/ShipperServices/AddressBook/address.services";

export default function Row1(props) {
  const { Flist, Tlist, StepCheck, SetStepCheck, SetFlist, SetTlist } = props;

  const [addressbook, setaddressbook] = useState([]);
  const [fromaddressid, setfromaddressid] = useState(0);
  const [toaddressid, settoaddressid] = useState(0);

  const getaddress = async () => {
    const response = await fetchAllAddresses();
    if (!response.error) {
      setaddressbook(response?.data?.data);
    }
  };
  useEffect(() => {
    getaddress();
  }, []);

  const handleSwap = () => {
    SetFlist((prevFlist) => {
      const { type: fType, ...restFlist } = prevFlist;
      return {
        ...Tlist,
        type: fType,
      };
    });
    SetTlist((prevTlist) => {
      const { type: tType, ...restTlist } = prevTlist;
      return {
        ...Flist,
        type: tType,
      };
    });
    SetStepCheck((prevState) => {
      return {
        ...prevState,
        Pickup_Address_bit: prevState.Delivery_Address_bit,
        Delivery_Address_bit: prevState.Pickup_Address_bit,
      };
    });
  };

  return (
    <>
      <Row className="mx-auto mt-5">
        <Col md={11} className="mx-auto">
          <div style={{ position: "relative" }}>
            <Row>
              {/* Ship From */}
              <Col md={6} style={{ zIndex: 1 }}>
                <AddressFormCard
                  StepCheck={StepCheck}
                  SetStepCheck={SetStepCheck}
                  Type={Flist["type"]}
                >
                  <AddressForm
                    List={Flist}
                    SetList={SetFlist}
                    StepCheck={StepCheck}
                    SetStepCheck={SetStepCheck}
                    Addressbook={addressbook}
                    setaddressid={setfromaddressid}
                  />

                  <AddressBook
                    AddressBook={addressbook}
                    SetAddressBook={setaddressbook}
                    StepCheck={StepCheck}
                    SetStepCheck={SetStepCheck}
                    List={Flist}
                    SetList={SetFlist}
                    addressid={fromaddressid}
                    setaddressid={setfromaddressid}
                  />
                </AddressFormCard>
              </Col>
              {/* Ship From */}
              {/* Ship to */}
              <Col md={6} style={{ zIndex: 1 }}>
                <AddressFormCard
                  StepCheck={StepCheck}
                  SetStepCheck={SetStepCheck}
                  Type={Tlist["type"]}
                >
                  <AddressForm
                    List={Tlist}
                    SetList={SetTlist}
                    StepCheck={StepCheck}
                    SetStepCheck={SetStepCheck}
                    Addressbook={addressbook}
                    setaddressid={settoaddressid}
                  />

                  <AddressBook
                    AddressBook={addressbook}
                    SetAddressBook={setaddressbook}
                    StepCheck={StepCheck}
                    SetStepCheck={SetStepCheck}
                    List={Tlist}
                    SetList={SetTlist}
                    addressid={toaddressid}
                    setaddressid={settoaddressid}
                  />
                </AddressFormCard>
              </Col>
              {/* Ship to */}
            </Row>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 2,
              }}
            >
              <Tooltip title="Swap">
                <IconButton onClick={handleSwap}>
                  <MdSwapHorizontalCircle
                    size={25}
                    style={{ color: "rgb(25 118 210)" }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
